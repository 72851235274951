import React, { useEffect, useState, SyntheticEvent } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, TextField, Tab, Tabs } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import icons from '../../../../assets/icons';
import { EMAIL, PHONE_HOME, PHONE_MOBILE, ADDRESS, WEBSITE, WHATSAPP, FAX } from '../../../../lib/constants/basiccontacttypes';
import { BEHANCE, FACEBOOK, INSTAGRAM, LINE, LINKEDIN, PINTEREST, SKYPE, SNAPCHAT, TELEGRAM, TIKTOK, TWITTER, VIBER, WECHAT, YOUTUBE } from '../../../../lib/constants/socialmediatypes';
import { useDispatch, useSelector } from 'react-redux';
import { setBreadcrumb } from '../../../../features/admin'
import { openResponseDialog } from '../../../../features/response-dialog';
import { openActionDialog } from '../../../../features/action-dialog'
import jobTitles from '../../../../lib/constants/jobTitles';
import FileHelper from '../../../../lib/helper/fileHelper'
import { startLoading, endLoading } from '../../../../features/loading';
import LivePreview from '../live-preview';
import { PORTAL_PROFILE_LIST } from '../../../../lib/constants/routes';
import UserDetails from './user-details';
import CustomSwitch from './customSwitch';
import SocialInformation from './social-information';
import ContactInformation from './contact-information';
import Gallery from './gallery';
import Videos from './videos';
import Files from './files';
import CustomDescription from './custom-description';
import ColorOption from '../../components/details/color-option'
import { setTheme as setThemeRedux, setThemeBackground as setThemeBackgroundRedux, setInitialThemeBackground, setInitialTheme } from '../../../../features/landing-page';
// import * as themeOptions from '../../../../lib/constants/themeOptions';
import profileApi from '../../../../api/profile/';
import { openErrorDialog } from '../../../../features/error-dialog';
import AppointmentStatus from './appointment-status';
import Voucher from './tabs/voucher';
import BindCard from './bind-card';
import auth from '../../../../api/auth';
import getVoucherListByProfile from '../../../../api/basicCampaign/getVoucherListByProfile.api';
const getUser = () => JSON.parse(localStorage.getItem('user') ?? '{}');

// filePath = 'user/uuid/profiles/userdetails/fileName';
// filePath = 'user/uuid/profiles/gallery/fileName';
// filePath = 'user/uuid/profiles/files/fileName';
async function uploadFile(
  filePath: string,
  file,
  shouldCompress?: boolean,
  maxSizeMB?: number,
  maxWidthOrHeight?: number,
) {
  let user = getUser();
  let fullPath = `user/${user.access_token_payload.uuid}/profiles/${filePath}/${uuidv4()}_${file.name}`;

  return await FileHelper.uploadFile(fullPath, file, shouldCompress, maxSizeMB, maxWidthOrHeight);
}

// a little function to help us with reordering the result
const reorder: any = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

// Remove an item from array
const remove: any = (list: any[], id: string) => list.filter(item => item.id !== id);

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  marginTop: grid * 2,
  background: isDragging ? "#FFEFBA" : null,

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  // background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: grid,
  width: '100%'
});

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#FECD20',
    },
    action: {
      selected: '#FECD20',
      selectedOpacity: 0.08,
    },
    background: {
      default: '#FFFFFF',
      paper: '#F9FAFC',
    },
  },
  typography: {
    fontFamily: 'Rubik Variable, sans-serif',
    h1: {
      fontSize: '31px',
      fontWeight: 400,
    },
    h3: {
      fontSize: '24px',
      fontWeight: 400,
    },
    h4: {
      fontSize: '16px',
      fontWeight: 500,
    },
    h5: {
      fontSize: '13px',
      fontWeight: 400,
    },
    button: {
      textTransform: 'none',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          color: '#000000',
          backgroundColor: '#FFEFBA',
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderTopRightRadius: '8px',
          borderBottomRightRadius: '8px',
          color: '#A7A7A7',
          '&.Mui-selected': {
            color: '#000000',
            backgroundColor: '#FECD20',
          },
          "&:hover": {
            backgroundColor: "#FFEFBA",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "#FECD20",
          },
        },
      },
    }
  },
});

interface FormError {
  name: string;
  phone: string;
  birthday: string;
  email: string;
  job_title: string;
  other_title: string;
  company_name: string;
  description: string;
}

export default function ProfileDetailsComponent() {
  const { state } = useLocation();
  const [publicName, setPublicName] = useState('');
  const [hasPublicName, setHasPublicName] = useState(false);
  const [profileImageFullPath, setProfileImageFullPath] = useState<string>('');
  const [file, setFile] = useState<any>();
  const [companyImageFullPath, setCompanyImageFullPath] = useState<string>('');
  const [companyImage, setCompanyImage] = useState<any>();
  const navigate = useNavigate();
  const [profileName, setProfileName] = useState<string>('Profile Name');
  const [isEditingProfileName, setIsEditingProfileName] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [jobTitle, setJobTitle] = useState<string>('');
  const [otherTitle, setOtherTitle] = useState<string>('');
  const [company, setCompany] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [urlInput, setUrlInput] = useState<string>('');
  const [contactSelect, setContactSelect] = useState<string>('');
  const [items, setItems] = useState<any[]>([]);
  const [socialSelect, setSocialSelect] = useState<string>('');
  const [socialItems, setSocialItems] = useState<any[]>([]);
  const [galleryItems, setGalleryItems] = useState<any[]>([]);
  const [docItems, setDocItems] = useState<any[]>([]);
  const [videoUrls, setVideoUrls] = useState<any[]>([]);
  const [isProfileActive, setIsProfileActive] = useState<boolean>(true);
  const [isEnabledAppointment, setIsEnabledAppointment] = useState<boolean>(true);
  const [isEnabledContactInfo, setIsEnabledContactInfo] = useState<boolean>(true);
  const [isEnabledSocialInfo, setIsEnabledSocialInfo] = useState<boolean>(true);
  const [isEnabledGallery, setIsEnabledGallery] = useState<boolean>(true);
  const [isEnabledFiles, setIsEnabledFiles] = useState<boolean>(true);
  const [isEnabledVideos, setIsEnabledVideos] = useState<boolean>(true);
  const [tabValue, setTabValue] = useState('BASICINFO');
  const [profileObject, setProfileObject] = useState<any>({});
  const [componentInfo, setComponentInfo] = useState<any[]>([])
  const [sections, setSections] = useState<any[]>([
    { section_name: 'social' },
    { section_name: 'contact' },
    { section_name: 'gallery' },
    { section_name: 'video' },
    { section_name: 'files' },
  ]);
  const dispatch = useDispatch()
  const params = useParams();
  const isConfirmed = useSelector<any>((state) => state.actionDialog.isConfirmed)
  const dialogAction = useSelector<any>(state => state.actionDialog.action);
  const [theme, setTheme] = React.useState<any>({});
  const [background, setBackground] = React.useState<string>('');
  const [errors, setErrors] = useState<FormError>({
    name: '',
    phone: '',
    birthday: '',
    email: '',
    job_title: '',
    other_title: '',
    company_name: '',
    description: ''
  });
  const [step, setStep] = useState<number>(1);
  const [accessRights, setAccessRights] = useState<string[]>([]);
  const [vouchers, setVouchers] = useState<any[]>([]);

  const getProfileVouchers = () => {
    const profileUUID = `${params.profileUuid}`;

    profileApi.getVoucherStatus(profileUUID)
      .then((response) => {
        if (Boolean(Number(response?.data?.active))) {
          getVoucherListByProfile({ profileUUID })
            .then(res => Promise.all(res.data.map(async (vc) => ({
              ...vc,
              banner_image: await FileHelper.getUrl(vc.banner_image),
            }))).then(setVouchers));
        } else {
          setVouchers([]);
        }
      })
      .catch(console.log);
  }

  useEffect(() => {
    getProfileVouchers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeProfileName = (profile_name: string) => {
    dispatch(startLoading());
    profileApi.updateProfile({
      profile_uuid: params.profileUuid,
      profiles_info: {
        ...profileObject,
        profile_name,
      },
    }).finally(() => {
      dispatch(endLoading());
    });
  }

  // Function to reorder section
  const reorderSection = (oldIndex: number, newIndex: number) => {
    const newSections = reorder(sections, oldIndex, newIndex - 1);
    setSections(newSections);
    setComponentInfo(newSections.filter(section => section.section_name === 'custom'));
  }

  const handleTabChange = (event: SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const deleteProfile = () => {
    dispatch(startLoading());
    profileApi.deleteProfile(`${params.profileUuid}`)
      .then(() => navigate(PORTAL_PROFILE_LIST))
      .finally(() => dispatch(endLoading()));
  }

  const handleDelete = (event) => {
    dispatch(openActionDialog({
      title: 'Delete Profile?',
      description: 'This action cannot be undone',
      action: 'deleteProfile',
    }));
  };

  const apiUrl = process.env.REACT_APP_API_HOST_URL;
  // const baseUrl = process.env.REACT_APP_BASE_URL;
  useEffect(() => {
    dispatch(setBreadcrumb({
      breadcrumb: [
        {
          name: "My Profile",
          path: PORTAL_PROFILE_LIST
        }, {
          name: "Profiles",
          path: PORTAL_PROFILE_LIST
        }, {
          name: profileName,
          path: null
        }
      ]
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    auth.getAccessLimit().then(value => setAccessRights(value.data.access_right));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (params.profileUuid !== undefined) {
      profileApi.getPublicName(`${params.profileUuid}`)
        .then(response => {
          setPublicName(response.data?.public_name ?? '');
          setHasPublicName(Boolean(response.data?.public_name));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let sects: any[] = [];
    dispatch(startLoading())
    if (params.profileUuid !== undefined || Boolean(state?.profileUuid)) {
      const profileUuid = params.profileUuid ?? state?.profileUuid;
      fetch(`${apiUrl}/profile/details?profile_uuid=${profileUuid}`, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${getUser().access_token}`,
        },
      })
        .then(res => res.json())
        .then(({ data }) => {
          setProfileName(data.profile_name);
          setIsProfileActive(data.profile_active);
          setIsEnabledAppointment(data.appointment_status);
          setName(data.user_details.full_name);
          dispatch(setThemeRedux(data.theme))
          dispatch(setThemeBackgroundRedux(data.background_color))

          const jTitle = jobTitles.filter(title => title === data.user_details.job_title).length > 0 ? data.user_details.job_title : 'Others';
          setJobTitle(jTitle);
          setOtherTitle(jTitle === 'Others' ? data.user_details.job_title : '');

          setCompany(data.user_details.company_name);
          setDescription(data.user_details.description);

          setProfileImageFullPath(data.user_details.profile_image);
          FileHelper.getUrl(data.user_details.profile_image)
            .then(url => setFile(url));

          setCompanyImageFullPath(data.user_details.company_image);
          FileHelper.getUrl(data.user_details.company_image)
            .then(url => setCompanyImage(url));

          setIsEnabledSocialInfo(data.social_info.active);
          setSocialItems(data.social_info.content.map(({ category, info }, index) => ({
            id: `${Date.now()}${index}`,
            category,
            info,
          })));
          sects.push({
            section_name: 'social',
            ...data.social_info,
          });

          setIsEnabledContactInfo(data.contact_info.active);
          setItems(data.contact_info.content.map(({ category, info }, index) => ({
            id: `${Date.now()}${index}`,
            category,
            info,
          })))
          sects.push({
            section_name: 'contact',
            ...data.contact_info,
          });

          setIsEnabledGallery(data.gallery.active);
          Promise.all(data.gallery.content.map(async ({ url, image_name }, index) => ({
            id: `${Date.now()}${index}`,
            file: await FileHelper.getUrl(url),
            full_path: url,
            image_name,
          }))).then(content => setGalleryItems(content));
          sects.push({
            section_name: 'gallery',
            ...data.gallery,
          });

          setIsEnabledVideos(data.video.active);
          Promise.all(
            data.video.content.map(async (vid, index) => addVideo(vid.url, index))
          ).then(videos => setVideoUrls(videos));
          sects.push({
            section_name: 'video',
            ...data.video,
          });

          setIsEnabledFiles(data.file.active);
          Promise.all(data.file.content.map(async ({ url, file_name }, index) => ({
            id: `${Date.now()}${index}`,
            file: await FileHelper.getUrl(url),
            full_path: url,
            file_name,
          }))).then(content => setDocItems(content));
          sects.push({
            section_name: 'files',
            ...data.file,
          });

          // var dataCount = data.custom_component.length;
          let customComponent = data.custom_component || [];
          if (customComponent.length > 0) {
            customComponent.sort((a, b) => a.sort - b.sort);
            customComponent = customComponent.map((cc: any, index: number) => ({
              sort: sections.length + index,
              cc_id: index,
              section_name: 'custom',
              ...cc,
            }));
          }

          setComponentInfo(customComponent);
          sects = sects.concat((customComponent ?? []));
          setProfileObject(data);

          const sorted = sects.sort((a, b) => a.sort - b.sort);
          setSections(sorted);
          dispatch(endLoading())
        }).finally(() => {
          dispatch(endLoading())
        });
    } else {
      dispatch(endLoading())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.profileUuid]);

  async function addVideo(urlInput, index) {
    let url = `https://youtube.com/oembed?url=${urlInput}&format=json`;
    let meta = await fetch(url).then(res => res.json()).catch(() => ({ title: '<Error getting title>' }));

    return {
      id: `${Date.now()}${index}`,
      url: urlInput,
      meta,
    };
  }

  const handleAddCustomComponent = () => {
    const newComponent = {
      sort: sections.length,
      cc_id: componentInfo.length,
      section_name: "custom",
      name: "My Custom Component",
      active: true,
      content: "This is my custom content"
    };

    setComponentInfo([
      ...componentInfo,
      newComponent,
    ]);
    setSections([
      ...sections,
      newComponent,
    ]);
  }

  const handleDeleteComponent = (index: number) => {
    const newSections = sections.filter((section: any, i: number) => i !== index);
    setSections(newSections);
    setComponentInfo(newSections.filter(section => section.section_name === 'custom'));
  }

  const handleEditComponentName = (index, value) => {
    const newSections = [
      ...sections.slice(0, index),
      {
        ...sections[index],
        name: value,
      },
      ...sections.slice(index + 1),
    ];
    setSections(newSections);
    setComponentInfo(newSections.filter(section => section.section_name === 'custom'));
  }

  const handleEditComponentContent = (index, value) => {
    const newSections = [
      ...sections.slice(0, index),
      {
        ...sections[index],
        content: value,
      },
      ...sections.slice(index + 1),
    ];
    setSections(newSections);
    setComponentInfo(newSections.filter(section => section.section_name === 'custom'));
  }

  const handleActiveComponent = (index) => {
    setSections([
      ...sections.slice(0, index),
      {
        ...sections[index],
        active: !sections[index].active,
      },
      ...sections.slice(index + 1),
    ]);
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setErrors({
      name: '',
      phone: '',
      birthday: '',
      email: '',
      job_title: '',
      other_title: '',
      company_name: '',
      description: ''
    });

    errors.name = '';
    errors.phone = '';
    errors.birthday = '';
    errors.email = '';
    errors.job_title = '';
    errors.other_title = '';
    errors.company_name = '';
    errors.description = '';

    var hasError = false;

    if (name === '') {
      setErrors({
        ...errors,
        name: 'Invalid name'
      });
      errors.name = 'Invalid name';
      hasError = true;
    }

    if (jobTitle === '') {
      setErrors({
        ...errors,
        job_title: 'Invalid job title'
      });
      errors.job_title = 'Invalid job title';
      hasError = true;
    }

    if (jobTitle === 'Others' && otherTitle === '') {
      setErrors({
        ...errors,
        other_title: 'Job title required'
      });
      errors.other_title = 'Job title required';
      hasError = true;
    }

    if (company === '') {
      setErrors({
        ...errors,
        company_name: 'Invalid company name'
      });
      errors.company_name = 'Invalid company name';
      hasError = true;
    }

    // if (description == '') {
    //   setErrors({
    //     ...errors,
    //     description: 'Invalid description'
    //   });
    //   errors.description = 'Invalid description';
    //   hasError = true;
    // }

    if (hasError) return;

    const sects = sections.map((section, index) => ({
      ...section,
      sort: index,
    }));
    dispatch(startLoading())
    const profileImageRequest = file !== undefined ? typeof file === 'string' ? profileImageFullPath : await uploadFile('userdetails', file) : undefined;
    const companyImageRequest = companyImage !== undefined ? typeof companyImage === 'string' ? companyImageFullPath : await uploadFile('userdetails', companyImage) : undefined;
    var profileObj = {
      "profile_name": profileName,
      "profile_active": isProfileActive,
      "user_details": {
        "full_name": name,
        "job_title": jobTitle !== 'Others' ? jobTitle : otherTitle,
        "company_name": company,
        "description": description,
        "profile_image": profileImageRequest,
        "company_image": companyImageRequest,
      },
      "contact_info": {
        "sort": sects.findIndex(sec => sec.section_name === 'contact'),
        "active": isEnabledContactInfo,
        "content": items.map(({ category, info }, index) => ({
          sort: index,
          category,
          info,
          style: [],
        })),
      },
      "social_info": {
        "sort": sects.findIndex(sec => sec.section_name === 'social'),
        "active": isEnabledSocialInfo,
        "content": socialItems.map(({ category, info }, index) => ({
          sort: index,
          category,
          info,
          style: [],
        })),
      },
      "gallery": {
        "sort": sects.findIndex(sec => sec.section_name === 'gallery'),
        "active": isEnabledGallery,
        "content": await Promise.all(galleryItems.map(async ({ file, image_name, full_path }, index) => ({
          sort: index,
          image_name: image_name ?? file.name ?? '',
          url: file !== undefined ? typeof file === 'string' ? full_path : await uploadFile('gallery', file) : undefined,
        }))),
      },
      "file": {
        "sort": sects.findIndex(sec => sec.section_name === 'files'),
        "active": isEnabledFiles,
        "content": await Promise.all(docItems.map(async ({ file, file_name, full_path }, index) => ({
          sort: index,
          file_name: file_name ?? file.name ?? '',
          url: file !== undefined ? typeof file === 'string' ? full_path : await uploadFile('files', file) : undefined,
        }))),
      },
      "video": {
        "sort": sects.findIndex(sec => sec.section_name === 'video'),
        "active": isEnabledVideos,
        "content": videoUrls.map(({ url }, index) => ({ sort: index, url }))
      },
      "theme": theme,
      "background_color": background,
      "custom_component": sects.filter(sect => sect.section_name === 'custom'),
    };
    setProfileObject(profileObj);
    setSections(sects);

    await saveProfile(profileObj).finally(() => {
      dispatch(endLoading())
    });
    // dispatch(confirmActionDialog())
  }

  const saveProfile = async (profileObj: any) => {
    let save = profileApi.addProfile;
    let body = profileObj;

    if (params.profileUuid !== undefined) {
      save = profileApi.updateProfile;
      body = {
        profile_uuid: params.profileUuid,
        profiles_info: profileObj,
      };
    }

    let response = await save(body);

    if (response.success) {
      dispatch(setInitialTheme(theme))
      dispatch(setInitialThemeBackground(background))
      dispatch(openResponseDialog({ title: "Save Successful", description: "Your changes have been successfully saved." }))
      if (params.profileUuid !== undefined) {
        // navigate(`/portal/profile/${params.profileUuid}/update`);
        navigate(PORTAL_PROFILE_LIST);
      } else {
        navigate(PORTAL_PROFILE_LIST);
      }
    } else {
      dispatch(openResponseDialog({ title: "Save Unsuccessful", description: response.message }))
    }
    return false;
  }

  function handleChange(e) {
    setFile(e.target.files[0]);
  }

  const getIcon = (iconName: string) => {
    var sx = { marginY: '22px', marginLeft: '20px', marginRight: '10px' };

    if (iconName === EMAIL) return <img src={icons.email_icon} alt='' style={sx} />;
    if (iconName === PHONE_HOME) return <img src={icons.telephone_icon} alt='' style={sx} />;
    if (iconName === WHATSAPP) return <img src={icons.grey_whatsapp_icon} alt='' style={sx} />;
    if (iconName === FAX) return <img src={icons.grey_fax_icon} alt='' style={sx} />;
    if (iconName === PHONE_HOME) return <img src={icons.telephone_icon} alt='' style={sx} />;
    if (iconName === PHONE_MOBILE) return <img src={icons.mobile_icon} alt='' style={sx} />;
    if (iconName === ADDRESS) return <img src={icons.location_icon} alt='' style={sx} />;
    if (iconName === WEBSITE) return <img src={icons.webpage_icon} alt='' style={sx} />;
    if (iconName === FACEBOOK) return <img src={icons.facebook_icon} alt='' style={sx} />;
    if (iconName === INSTAGRAM) return <img src={icons.instagram_icon} alt='' style={sx} />;
    if (iconName === LINKEDIN) return <img src={icons.linkedin_icon} alt='' style={sx} />;
    if (iconName === TWITTER) return <img src={icons.x_icon} alt='' style={sx} />;
    if (iconName === LINE) return <img src={icons.line_icon} alt='' style={sx} />;
    if (iconName === SNAPCHAT) return <img src={icons.snapchat_icon} alt='' style={sx} />;
    if (iconName === SKYPE) return <img src={icons.skype_icon} alt='' style={sx} />;
    if (iconName === BEHANCE) return <img src={icons.behance_icon} alt='' style={sx} />;
    if (iconName === YOUTUBE) return <img src={icons.youtube_icon} alt='' style={sx} />;
    if (iconName === PINTEREST) return <img src={icons.pinterest_icon} alt='' style={sx} />;
    if (iconName === TELEGRAM) return <img src={icons.telegram_icon} alt='' style={sx} />;
    if (iconName === WECHAT) return <img src={icons.wechat_icon} alt='' style={sx} />;
    if (iconName === TIKTOK) return <img src={icons.tiktok_icon} alt='' style={sx} />;
    if (iconName === VIBER) return <img src={icons.viber_icon} alt='' style={sx} />;

    return <img src={icons.webpage_icon} alt='' style={sx} />;
  }

  function handleSelectClose(e) {
    if (e.target.getAttribute('data-value') === null) return;
    setItems([
      ...items,
      {
        id: `${Date.now()}`,
        category: e.target.getAttribute('data-value'),
        info: ''
      }
    ]);
  }

  function handleSocialSelectClose(e) {
    if (e.target.getAttribute('data-value') === null) return;
    setSocialItems([
      ...socialItems,
      {
        id: `${Date.now()}`,
        category: e.target.getAttribute('data-value'),
        info: ''
      }
    ]);
  }

  function handleCompanyImageChange(e) {
    setCompanyImage(e.target.files[0]);
  }

  function handleGalleryChange(e) {
    setGalleryItems([
      ...galleryItems,
      {
        id: Date.now().toString(),
        file: e.target.files[0],
      },
    ]);
  }

  function handleDocChange(e) {
    const fileSize = e.target.files[0].size;
    const fileSizeInMB = fileSize / (1024 * 1024);
    if (fileSizeInMB > 25) {
      dispatch(openErrorDialog({ title: "File Size Exceeded", description: "File size should not exceed 25MB" }))
      return;
    }
    setDocItems([
      ...docItems,
      {
        id: Date.now().toString(),
        file: e.target.files[0],
      },
    ]);
  }

  const setItem = (e, index: number) => {
    var { id, category } = items[index];
    setItems([
      ...items.slice(0, index),
      { id, category, info: e.target.value },
      ...items.slice(index + 1),
    ]);
  }

  const setSocialItem = (e, index: number) => {
    var { id, category } = socialItems[index];
    setSocialItems([
      ...socialItems.slice(0, index),
      { id, category, info: e.target.value },
      ...socialItems.slice(index + 1),
    ]);
  }

  useEffect(() => {
    if (isConfirmed && dialogAction === "deleteProfile") deleteProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirmed])

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Container style={{ paddingLeft: 0, paddingRight: 0 }} sx={{ my: 4 }}>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
            <span style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
              {isEditingProfileName ? (
                <TextField
                  color='secondary'
                  margin="dense"
                  required
                  id="email"
                  name="email"
                  value={profileName}
                  onChange={e => setProfileName(e.target.value)}
                  autoFocus
                />
              ) : (
                <Typography component='span' variant='h1'>
                  {profileName}
                </Typography>
              )}

              <IconButton aria-label="edit" onClick={e => {
                if (isEditingProfileName && params.profileUuid !== undefined) {
                  changeProfileName(profileName);
                }
                setIsEditingProfileName(!isEditingProfileName);
              }}>
                {isEditingProfileName ? <img src={icons.thumbs_up} alt='' /> : <img src={icons.edit_02} alt='' />}
              </IconButton>
            </span>
            {Boolean(params.profileUuid) && <Button
              onClick={handleDelete}
              startIcon={<div className='icon' style={{
                backgroundColor: '#EB3325',
                mask: `url(${icons.trash_icon_btn})`,
                maskSize: 'cover',
                maskRepeat: 'no-repeat',
                WebkitMask: `url(${icons.trash_icon_btn})`,
                WebkitMaskSize: 'cover',
                WebkitMaskRepeat: 'no-repeat',
                width: '16px',
                height: '20px',
              }} />}
              sx={{
                color: '#EB3325',
                '&:hover': {
                  backgroundColor: 'rgba(235, 51, 37, 0.2)',
                },
              }}
            >
              Delete Profile
            </Button>}
          </div>
          <Box component="form" onSubmit={handleSubmit} sx={{ display: { xs: 'block', lg: 'flex' }, gap: '20px', justifyContent: 'center' }} noValidate>
            <Box sx={{ flex: 1, display: step === 2 ? 'none' : 'block' }}>
              {Boolean(params.profileUuid) && <Box sx={{ bgcolor: 'white', mb: '20px', border: { xs: 0, sm: "2px solid #FECD20" }, borderRadius: '8px', padding: { xs: 0, sm: '1.5rem' }, flex: 1, width: '100%' }}>
                <Typography sx={{ fontWeight: 500 }} gutterBottom>Public Name</Typography>
                <Typography sx={{ fontWeight: 400, fontSize: '13px' }}>• {process.env.REACT_APP_BASE_URL}/w/{Boolean(publicName) ? publicName : 'profile-name'}</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography component='label' htmlFor='public-name' variant='h5' sx={{ mr: '10px' }}>
                    Public name:
                  </Typography>
                  <TextField
                    disabled={hasPublicName}
                    color='secondary'
                    margin="dense"
                    name="public-name"
                    sx={{ bgcolor: 'white', borderRadius: '4px' }}
                    value={publicName}
                    onChange={event => setPublicName(event.target.value)}
                  />
                </Box>
                {!hasPublicName && <>
                  <Typography sx={{ fontWeight: 400, fontSize: '13px', color: '#A7A7A7' }} gutterBottom>Note: This can only be done once. Once set, it is uneditable.</Typography>
                  <Button
                    variant='contained'
                    sx={{ borderRadius: '30px', color: '#FECD20', '&:hover': { bgcolor: '#373736' } }}
                    onClick={() => {
                      profileApi.editPublicName(`${params.profileUuid}`, publicName)
                        .then(response => {
                          dispatch(openResponseDialog({
                            title: 'Public Name Set',
                            description: response.message,
                          }));
                          setHasPublicName(true);
                        })
                        .catch(response => dispatch(openErrorDialog({
                          title: 'Failed to Set Public Name',
                          description: response.message,
                        })));
                    }}
                  >
                    Update Link
                  </Button>
                </>}
              </Box>}
              <Box sx={{ border: { xs: 0, sm: "2px solid #FECD20" }, bgcolor: 'white', borderRadius: '4px', padding: { xs: 0, sm: '1.5rem' }, flex: 1, width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
                  <Tabs value={tabValue} onChange={handleTabChange} variant='scrollable'>
                    <Tab label="Basic Info" value="BASICINFO" />
                    {accessRights.includes('profile appointment edit') && Boolean(params.profileUuid) && <Tab label="Appointment" value="APPOINTMENT" />}
                    {accessRights.includes('profile voucher edit') && Boolean(params.profileUuid) && <Tab label="Voucher" value="VOUCHER" />}
                    {Boolean(params.profileUuid) && <Tab label="Bind NFC Card" value="NFC" />}
                  </Tabs>
                </Box>
                {
                  tabValue === 'BASICINFO' && (
                    <>
                      <ColorOption handleTheme={setTheme} handleBackground={setBackground} />
                      <UserDetails
                        errors={errors}
                        name={name}
                        setName={setName}
                        jobTitle={jobTitle}
                        setJobTitle={setJobTitle}
                        otherTitle={otherTitle}
                        setOtherTitle={setOtherTitle}
                        company={company}
                        setCompany={setCompany}
                        description={description}
                        setDescription={setDescription}
                        file={file}
                        handleChange={handleChange}
                        companyImage={companyImage}
                        handleCompanyImageChange={handleCompanyImageChange}
                      />

                      {sections.map((section, index) => {
                        if (section.section_name === "social") return <SocialInformation
                          key={index}
                          totalSections={sections.length}
                          order={index}
                          reorderSection={reorderSection}
                          socialItems={socialItems}
                          setSocialItems={setSocialItems}
                          socialSelect={socialSelect}
                          setSocialSelect={setSocialSelect}
                          errors={errors}
                          isEnabledSocialInfo={isEnabledSocialInfo}
                          setIsEnabledSocialInfo={setIsEnabledSocialInfo}
                          setSocialItem={setSocialItem}
                          handleSocialSelectClose={handleSocialSelectClose}
                          getListStyle={getListStyle}
                          getItemStyle={getItemStyle}
                          getIcon={getIcon}
                          reorder={reorder}
                          remove={remove}
                        />
                        if (section.section_name === "contact") return <ContactInformation
                          key={index}
                          errors={errors}
                          totalSections={sections.length}
                          order={index}
                          reorderSection={reorderSection}
                          contactSelect={contactSelect}
                          setContactSelect={setContactSelect}
                          isEnabledContactInfo={isEnabledContactInfo}
                          setIsEnabledContactInfo={setIsEnabledContactInfo}
                          items={items}
                          setItems={setItems}
                          setItem={setItem}
                          handleSelectClose={handleSelectClose}
                          reorder={reorder}
                          remove={remove}
                          getListStyle={getListStyle}
                          getItemStyle={getItemStyle}
                          getIcon={getIcon}
                        />
                        if (section.section_name === "gallery") return <Gallery
                          key={index}
                          totalSections={sections.length}
                          order={index}
                          reorder={reorder}
                          reorderSection={reorderSection}
                          galleryItems={galleryItems}
                          setGalleryItems={setGalleryItems}
                          isEnabledGallery={isEnabledGallery}
                          setIsEnabledGallery={setIsEnabledGallery}
                          handleGalleryChange={handleGalleryChange}
                          getListStyle={getListStyle}
                          getItemStyle={getItemStyle}
                          remove={remove}
                        />
                        if (section.section_name === "video") return <Videos
                          key={index}
                          totalSections={sections.length}
                          order={index}
                          reorder={reorder}
                          reorderSection={reorderSection}
                          videoUrls={videoUrls}
                          setVideoUrls={setVideoUrls}
                          isEnabledVideos={isEnabledVideos}
                          setIsEnabledVideos={setIsEnabledVideos}
                          getListStyle={getListStyle}
                          getItemStyle={getItemStyle}
                          remove={remove}
                          urlInput={urlInput}
                          setUrlInput={setUrlInput}
                        />
                        if (section.section_name === "files") return <Files
                          key={index}
                          totalSections={sections.length}
                          order={index}
                          reorder={reorder}
                          reorderSection={reorderSection}
                          docItems={docItems}
                          setDocItems={setDocItems}
                          isEnabledFiles={isEnabledFiles}
                          setIsEnabledFiles={setIsEnabledFiles}
                          getListStyle={getListStyle}
                          getItemStyle={getItemStyle}
                          remove={remove}
                          handleDocChange={handleDocChange}
                        />
                        if (section.section_name === "custom") return <CustomDescription
                          key={`${index}_${section.cc_id}`}
                          index={index}
                          totalSections={sections.length}
                          reorderSection={reorderSection}
                          active={section.active}
                          name={section.name}
                          content={section.content}
                          handleDeleteComponent={handleDeleteComponent}
                          handleEditComponentName={handleEditComponentName}
                          handleEditComponentContent={handleEditComponentContent}
                          handleActiveComponent={handleActiveComponent}
                        />
                        return <></>
                      })}

                      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "15px" }}>
                        <Button
                          id='add-component'
                          variant="contained"
                          sx={{ borderRadius: '30px', color: 'black', backgroundColor: '#FECD20' }}
                          onClick={handleAddCustomComponent}
                          startIcon={<img src={icons.plus_icon} alt="" />}
                        >
                          Add Component
                        </Button>
                      </div>
                    </>)}
                {tabValue === 'APPOINTMENT' && Boolean(params.profileUuid) && <AppointmentStatus status={isEnabledAppointment} onChange={(event) => {
                  const checked = event.target.checked;
                  profileApi.updateAppointmentStatus(`${params.profileUuid}`, checked ? '1' : '0');
                  setIsEnabledAppointment(checked);
                }} />}
                {
                  tabValue === 'VOUCHER' && Boolean(params.profileUuid) && (
                    <>
                      <Voucher getProfileVouchers={getProfileVouchers} />
                    </>
                  )
                }
                {tabValue === 'NFC' && Boolean(params.profileUuid) && <BindCard currentProfileName={profileName} />}
                <Button
                  fullWidth
                  variant="contained"
                  color='secondary'
                  sx={{
                    fontSize: '16px',
                    fontWeight: '500',
                    textTransform: 'none',
                    borderRadius: '30px',
                    display: { xs: 'inline-flex', lg: 'none' },
                    mt: '15px',
                  }}
                  onClick={() => setStep(2)}
                >
                  Next
                </Button>
              </Box>
            </Box>
            {Boolean(profileObject) && <Box sx={{
              background: { sm: 'white' },
              display: step === 2 ? 'flex' : {
                xs: 'none',
                lg: 'flex',
              },
              position: 'sticky',
              top: '100px',
              height: '760px',
              flexDirection: 'column',
              alignItems: 'center',
              border: { sm: '2px solid #FECD20' },
              borderRadius: '4px',
              p: { sm: '20px' },
            }}>
              <Button
                fullWidth
                variant="outlined"
                sx={{
                  fontSize: '16px',
                  fontWeight: '500',
                  borderRadius: '30px',
                  display: { xs: 'inline-flex', lg: 'none' },
                  mb: '20px',
                }}
                startIcon={<img alt="back_icon" src={icons.back_icon} />}
                onClick={() => setStep(1)}
              >
                Back
              </Button>
              <Box sx={{ width: '100%', display: "flex", justifyContent: "space-between", alignItems: 'flex-start' }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ borderRadius: '30px', color: '#FECD20', '&:hover': { bgcolor: '#373736' } }}
                >
                  {params.profileUuid !== undefined ? 'Update Profile' : 'Create Profile'}
                </Button>
                <Paper elevation={0} sx={{ background: { sm: 'white' }, mb: '20px' }}>
                  <CustomSwitch checked={isProfileActive} onChange={e => {
                    if (Boolean(params.profileUuid)) {
                      profileApi.editProfileStatus(`${params.profileUuid}`, e.target.checked);
                    }
                    setIsProfileActive(e.target.checked);
                  }} />
                </Paper>
              </Box>
              <LivePreview {...{
                profileName,
                isProfileActive,
                isEnabledAppointment,
                name,
                jobTitle,
                otherTitle,
                company,
                description,
                file,
                profileImageFullPath,
                companyImage,
                companyImageFullPath,
                isEnabledContactInfo,
                items,
                isEnabledSocialInfo,
                socialItems,
                isEnabledGallery,
                galleryItems,
                isEnabledFiles,
                docItems,
                isEnabledVideos,
                videoUrls,
                componentInfo,
                sections,
                vouchers,
              }} />

            </Box>}
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

