import React from "react";
import { Box, Tabs, Tab } from "@mui/material";
import LoginSecurity from "./components/loginSecurity";
import PersonalInfo from "./components/personalInfo";
import Order from "./components/order";
import SubscriptionTab from "./components/subscriptionTab";
import ReferralTab from "./components/referralTab";
import { useSearchParams } from "react-router-dom";

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export default function SettingComponent({ isSocialSignin }: { isSocialSignin: boolean }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = parseInt(searchParams.get("tab") || '0');
  const [value, setValue] = React.useState(tab);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setSearchParams({ tab: newValue.toString() });
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} variant="scrollable" onChange={handleChange}>
          <Tab sx={{ color: "black !important" }} label="Personal Info" {...a11yProps(0)} />
          <Tab sx={{ color: "black !important", display: isSocialSignin ? 'none' : undefined }} label="Login & Security" {...a11yProps(1)} />
          <Tab sx={{ color: "black !important" }} label="Plan & Billings" {...a11yProps(2)} />
          <Tab sx={{ color: "black !important" }} label="Order & Tracking" {...a11yProps(3)} />
          <Tab sx={{ color: "black !important" }} label="Referral" {...a11yProps(4)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <PersonalInfo />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <LoginSecurity />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <SubscriptionTab />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <Order />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <ReferralTab />
      </CustomTabPanel>
    </>
  );
};