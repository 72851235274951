import React, {useEffect,useState} from 'react';
import OrderComponent  from './order.component';
import orderApi from '../../../../api/order';
import { startLoading,endLoading } from '../../../../features/loading';
import { useDispatch } from 'react-redux';
import { ListOrder } from '../../../../lib/types/Order';
import { useNavigate } from 'react-router-dom';
import { BUSINESS, LIFESTYLE, LIFESTYLE_LIFETIME, LIFESTYLE_YEARLY } from '../../../../lib/constants/subscriptionType';

const getPlanName = (subscription_id: number) => {
  switch (subscription_id) {
    case LIFESTYLE:
      return '(Monthly)';
    case LIFESTYLE_YEARLY:
      return '(Yearly)';
    case LIFESTYLE_LIFETIME:
      return '(Lifetime)';
    case BUSINESS:
      return '(Business)';
    default:
      return '';
  }
}

export default function OrderContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [orderList, setOrderList] = useState<ListOrder[]>([]); 
  const getOrderList = async () => {
    dispatch(startLoading());
    await orderApi.getList().then((res) => {
      if(res.success){
        setOrderList(res.data);
      }
    }).finally(() => {
      dispatch(endLoading());
    });
  }

  const handlePayNow = async (orderUUID:string) => {
    navigate(`/portal/order/repaid/${orderUUID}`);
  }

  useEffect(() => {
   getOrderList();
   // eslint-disable-next-line
  }, []);
  return <OrderComponent
    orderList={orderList}
    handlePayNow={handlePayNow}
    getPlanName={getPlanName}
  />;
}
