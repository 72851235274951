import React from 'react';
import { Box, Typography } from '@mui/material';
import images from '../../assets/img';
import { NFC, SUBSCRIPTION } from '../../lib/constants/orderType';
import { BUSINESS, LIFESTYLE, LIFESTYLE_LIFETIME, LIFESTYLE_YEARLY } from '../../lib/constants/subscriptionType';
import moment from 'moment';

const getPlanName = (subscription_id: number) => {
  switch (subscription_id) {
    case LIFESTYLE:
      return '(Monthly)';
    case LIFESTYLE_YEARLY:
      return '(Yearly)';
    case LIFESTYLE_LIFETIME:
      return '(Lifetime)';
    case BUSINESS:
      return '(Business)';
    default:
      return '';
  }
}

export default function NFCInvoice({ order }: { order: any }) {
  return (
    //temporay hiding invoice template with position fixed
    <div style={{ position: 'fixed', width: '1000px', opacity: 0 }}>
      <Box id="invoice-template" sx={{ p: "4rem", display: "flex", flexDirection: "column", justifyContent: 'space-between', height: "1800px", width: '100%' }}>
        <Box>
          <Box height='200px'>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
              <Typography variant="h2" fontWeight="500">Receipt</Typography>
              <img src={images.invoice_logo.toString()} alt="invoice-logo" />
            </Box>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', pb: '3rem' }}>
            <Box>
              <Typography fontSize="20px">Receipt Date</Typography>
              <Typography fontSize="20px" fontWeight='500px'>{moment(order?.created_at).format('DD MMM YYYY')}</Typography>
            </Box>
            <Box>
              <Typography fontSize="20px">Receipt No.</Typography>
              <Typography fontSize="20px" fontWeight='500px'>{order?.order_ref_id}</Typography>
            </Box>
            <Box>
              <Typography fontSize="20px">Referral Code</Typography>
              <Typography fontSize="20px" fontWeight='500px'>{order?.ref_code || 'N/A'}</Typography>
            </Box>
          </Box>

          <Box sx={{ dsiplay: 'flex', flexDirection: 'row', gap: '1rem', pb: '3rem' }}>
            <Typography fontSize="20px">To</Typography>
            <Typography fontSize="20px">{order?.customer_name}</Typography>
            <Typography fontSize="20px">{order?.customer_email}</Typography>
          </Box>
          <Box sx={{ dsiplay: 'flex', flexDirection: 'row', gap: '2rem' }}>
            <Typography fontSize="20px" fontWeight='500'>Product(s)</Typography>
            {
              Number(order?.type) === NFC &&
              order?.products.map((product: any, index: number) =>
                <Box key={index} sx={{ display: "grid", gridTemplateColumns: '1fr 1fr', columnGap: "1rem", pb: '2rem' }}>
                  <Typography fontSize="20px">My Pixel Card : {product?.card_name}</Typography>
                  <Typography fontSize="20px" textAlign='end'></Typography>

                  <Typography fontSize="20px">Product Price</Typography>
                  <Typography variant="h5" fontSize="20px" textAlign='end'>{order?.currency} {Number(product?.price).toFixed(2)}</Typography>

                  {Boolean(Number(product?.discount)) && <>
                    <Typography fontSize="20px">Discount</Typography>
                    <Typography fontSize="20px" textAlign='end'>-{order?.currency} {Number(product?.discount).toFixed(2)}</Typography>
                  </>}

                  {Number(product?.customization) === 1 && <>
                    <Typography fontSize="20px">Customization</Typography>
                    <Typography fontSize="20px" textAlign='end'>{order?.currency} {Number(product?.customization_price).toFixed(2)}</Typography>
                  </>}

                  {Number(product?.pixelcare) === 1 && <>
                    <Typography fontSize="20px">PixelCare+</Typography>
                    <Typography fontSize="20px" textAlign='end'>{order?.currency} {Number(product?.pixelcare_price).toFixed(2)}</Typography>
                  </>}
                </Box>
              )
            }

            {Number(order?.type) === SUBSCRIPTION && <Box sx={{ display: "grid", gridTemplateColumns: '1fr 1fr', columnGap: "1rem", pb: '2rem' }}>
              <Typography fontSize="20px">Lifestyle Plan subscription {getPlanName(Number(order?.subscription_id))}</Typography>
              <Typography variant="h5" fontSize="20px" textAlign='end'>{order?.currency} {Number(order?.total_amount).toFixed(2)}</Typography>
            </Box>}
          </Box>
          <hr></hr>
          <Box sx={{ display: "grid", gridTemplateColumns: '1fr 1fr' }}>
            {/* Below box is temporary placed here for alignment purposes */}
            <Box></Box>
            {/* <Typography>Paid with Master Card **** 7979 </Typography> */}
            <Box sx={{ display: "grid", gridTemplateColumns: '1fr 1fr', columnGap: "1rem" }}>
              {Number(order?.type) === NFC && <>
                <Typography fontSize="20px">Shipping Fee</Typography>
                <Typography fontSize="20px" textAlign='end'>{order?.currency} {Number(order?.shipping_fee).toFixed(2)}</Typography>
              </>}

              <Typography fontSize="20px">Total</Typography>
              <Typography fontSize="20px" textAlign='end'>{order?.currency} {Number(order?.total_amount).toFixed(2)}</Typography>

              <Typography fontSize="20px">Includes Tax</Typography>
              <Typography fontSize="20px" textAlign='end'></Typography>

              <Typography fontSize="20px">Total Charged</Typography>
              <Typography fontSize="20px" textAlign='end'>{order?.currency} {Number(order?.total_amount).toFixed(2)}</Typography>
            </Box>
          </Box>
        </Box>

        <Box>
          <Typography fontSize="20px">Please retain for your records.</Typography>
          <Typography fontSize="20px">Pixel Ready Sdn. Bhd.</Typography>
          <Typography fontSize="20px">Jalan Manis 1, Cheras Plaza, Kuala Lumpur.</Typography>
          <Typography fontSize="20px">Copyright © 2024 Pixel Card. All rights reserved.</Typography>
        </Box>
      </Box>
    </div>
  )
}