import React, { useEffect } from "react";
import SubscriptionComponent from "./subscription.component";
import { CONTACT, PAYMENT, PORTAL_SETTINGS, SETTINGS, SUBSCRIPTION, COMPLETED } from "../../../../lib/constants/routes";
import { setBreadcrumb } from "../../../../features/admin";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";
import { startLoading, endLoading } from "../../../../features/loading";
import { openResponseDialog } from "../../../../features/response-dialog";
import subscriptionApi from "../../../../api/subscription";
import authApi from "../../../../api/auth";
import moment from "moment";
import * as subscriptionType from "../../../../lib/constants/subscriptionType";
import { openActionDialog } from "../../../../features/action-dialog";
export default function SubscriptionContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [trialled, setTrialled] = React.useState(false);
  const [subscriptionId, setSubscriptionId] = React.useState(1);
  const [selectedSubId, setSelectedSubId] = React.useState(0);
  const [expDate, setExpDate] = React.useState("");
  const isConfirmed = useSelector<any>(state => state.actionDialog.isConfirmed);
  const dialogAction = useSelector<any>(state => state.actionDialog.action);

  useEffect(() => {
    dispatch(setBreadcrumb({
      breadcrumb: [
        { name: "Settings", path: SETTINGS },
        { name: "Subscription", path: null }
      ]
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBack = () => {
    navigate({
      pathname: PORTAL_SETTINGS,
      search: '?tab=2',
    });
  }

  const handleSubscribe = (isYearly: boolean) => {
    navigate({
      pathname: `${PORTAL_SETTINGS}/${SUBSCRIPTION}/${PAYMENT}`,
      search: createSearchParams({ is_yearly: isYearly ? '1' : '0' }).toString(),
    });
  }

  const handleSetAutoRenew = () => {
    subscriptionApi.setAutoRenew().then(() => {
      if (Number(subscriptionId) === 3) {
        setSelectedSubId(3);
      } else {
        setSelectedSubId(2);
      }

      dispatch(openResponseDialog({
        title: "Auto-Renewal Enabled",
        description: "You will not be charged until current subscription expires",
        action: 'enableAutoRenewal'
      }));
    });
  }

  const handleContact = () => {
    navigate(`${PORTAL_SETTINGS}/${SUBSCRIPTION}/${CONTACT}`);
  }

  const handleTrial = async () => {
    dispatch(startLoading());
    await subscriptionApi.subscribeFreeTrial()
      .then(response => {
        if (response.success) {
          const exp_date = moment(response.data.end_date).format("YYYY-MM-DD");
          navigate(
            {
              pathname: `/${SUBSCRIPTION}${COMPLETED}`,
            },
            { state: { exp_date } },
          );
          return true
        }
        dispatch(openResponseDialog({ title: "Subscription failed", description: response.message }))
      })
      .catch(error => {
        dispatch(endLoading());
        dispatch(openResponseDialog({ title: "Subscription failed", description: error.message }))
      }).finally(() => {
        dispatch(endLoading());
      })
  }

  const handleFree = async () => {
    dispatch(startLoading());
    await subscriptionApi.changePlan(subscriptionType.FREE_TIER).then(response => {
      if (response.success) {
        navigate(
          {
            pathname: `/${SUBSCRIPTION}${COMPLETED}`,
          }
        );
        return true
      }
      dispatch(openResponseDialog({ title: "Subscription failed", description: response.message }))
    }).catch(error => {
      dispatch(openResponseDialog({ title: "Subscription failed", description: error.message }))
    }).finally(() => {
      dispatch(endLoading());
    })
  }
  const getAccessLimit = async () => {
    authApi.getAccessLimit()
      .then(response => {
        setTrialled(Boolean(response.data.end_date));
        setSubscriptionId(Number(response.data.subscription_id));
        setSelectedSubId(Number(response.data.selected_subscriptions_id));
        setExpDate(() => moment(response.data.end_date).format('YYYY-MM-DD'));
      })
      .catch(console.log);
  }

  // Function called to ask user to confirm
  const handleUnsubscribe = () => {
    dispatch(openActionDialog({
      title: "Unsubscribe?",
      description: "You will no longer have access to lifestyle features and some of the data once the current subscription expires.",
      action: "unsubscribe"
    }));
  }

  // Function called when the user confirmed
  const unsubscribe = () => {
    subscriptionApi.downgradeToFree().then(() => {
      setSelectedSubId(1);
      dispatch(openResponseDialog({
        title: "Unsubscribed",
        description: "Your subscription will not be renewed automatically",
        action: 'disableAutoRenewal'
      }));
    });
  }

  useEffect(() => {
    getAccessLimit();
  }, []);

  useEffect(() => {
    if (isConfirmed && dialogAction === "unsubscribe") unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirmed, dialogAction])

  return <SubscriptionComponent
    currentTier={subscriptionId}
    handleBack={handleBack}
    handleFree={handleFree}
    handleSubscribe={handleSubscribe}
    handleUnsubscribe={handleUnsubscribe}
    handleSetAutoRenew={handleSetAutoRenew}
    handleContact={handleContact}
    handleTrial={handleTrial}
    selectedTier={selectedSubId}
    trialled={trialled}
    expDate={expDate}
  />
}