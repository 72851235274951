import axios from "axios";

interface Data {
  code: number;
  data: Details;
  message: string | null;
  success: boolean;
}

interface Details {
  order_uuid: string;
  order_ref_id: string;
  bill_name: string;
  bill_email: string;
  bill_mobile: string;
  bill_desc: string;
  bill_addr1: string;
  bill_addr2: string;
  bill_city: string;
  bill_state: string;
  bill_zip: string;
  country: string;
  currency: string;
  channel: string;
  payment_type: string;
  status: string;
  payment_status: string;
  total_amount: string;
  shipping_fee: string;
  created_at: string;
  updated_at: string;
  products: any;
  payment: any;
  shipping: any;
  subscription_id: number;
}


const getDetails = (orderUUID: string): Promise<Data> => {
  const user = JSON.parse(localStorage.getItem('user') ?? '{}');
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${user.access_token}`;

  const params = new URLSearchParams([['order_uuid', orderUUID]]);
  return new Promise((resolve, reject) => {
    axios.get("order/details", { params })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  })
};

export default getDetails;
